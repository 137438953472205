import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";

const SuccessSubscriptionPage = () => {
  return (
    <BoxInfoTemplate
      title="Subscription Succesfull"
      subTitle="GRACIAS"
      mainText="Success! Gracias amig@!! Nos vemos en clase! 😀❤."
      iconType="check"
      borderColor="green"
      showQuotes={false}
    />
  );
};

export default SuccessSubscriptionPage;
