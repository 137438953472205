import Layout from "../../containers/Layout";
import VideoTemplate from "../VideoTemplate/VideoTemplate";

const mainTextContent = `

Enhance your Spanish skills by joining our free Telegram channel! You'll receive daily exercises, in-depth explanations, and the latest updates from our Spanish learning website. Connect directly with fellow learners to exchange tips and practice speaking. Plus, get quality content and essential advice for living or traveling in Spain. Don’t miss out on this comprehensive and interactive learning opportunity—join us today!
`;

const GetStarted = () => {
  return (
    <Layout mt={0}>
      <VideoTemplate
        imageUrl="/images/Telegram-channels-SWA.png"
        imageAlt="Descripción alternativa de la imagen"
        mainTitle="GET THE BEST IN YOUR PHONE"
        subTitle=""
        mainText={mainTextContent}
        buttonLabel="Telegram Group"
        linkPath="https://t.me/+S78OquqBTZdmNGNk"
        showButton={true}
      />
    </Layout>
  );
};

export default GetStarted;
